<!-- 佣金配置页面 -->
<template>
  <div class="commissionConfig">
    <Tabs v-model="tabVal" :animated="false">
      <TabPane label="佣金配置" name="1">
        <div class="config-content">
          <Form :model="formData1" :label-width="140">
            <FormItem label="佣金冻结时间(天)：">
              <Input v-model="formData1.brokerageRateOne" style="width:400px"></Input>
            </FormItem>
            <FormItem label="一级返佣比例：">
              <Input v-model="formData1.brokerageRateTwo" style="width:400px"></Input>
            </FormItem>
            <FormItem label="二级返佣比例：">
              <Input v-model="formData1.brokerageExtractTime" style="width:400px"></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save(1)">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
      <TabPane label="分销配置" name="2">
        <div class="config-content">
          <Form :model="formData2" :label-width="140">
            <FormItem label="分销模式：">
              <Input v-model="formData2.brokerageModel" style="width:400px"></Input>
            </FormItem>
            <FormItem label="分销启用：">
              <RadioGroup v-model="formData2.brokerageOpen">
                <Radio label="true">启用</Radio>
                <Radio label="false">停用</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save(2)">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { findBrokerageConfig, editBrokerageConfig, findDistributionConfig, editDistributionConfig } from '@/api/systemConfiguration.js'
export default {
  data() {
    return {
      tabVal: '1',
      formData1: {
        brokerageRateOne: '',
        brokerageRateTwo: '',
        brokerageExtractTime: ''
      },
      formData2: {
        brokerageModel: '',
        brokerageOpen: 'true'
      }
    };
  },
  created() {
    this.findBrokerageConfig()
    this.findDistributionConfig()
  },
  methods: {
    findBrokerageConfig() {
      findBrokerageConfig().then(res => {
        if (res.success) {
          this.formData1 = res.result
        }
      })
    },
    findDistributionConfig() {
      findDistributionConfig().then(res => {
        if (res.success) {
          this.formData2 = res.result
        }
      })
    },
    save(type) {
      if (type == 1) {
        editBrokerageConfig(this.formData1).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      } else {
        editDistributionConfig(this.formData2).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      }

    },
  }
}

</script>
<style lang='scss'>
.commissionConfig {
  height: calc(100vh - 150px);
  .config-content {
    height: calc(100vh - 200px);
    padding-top: 10px;
    overflow: auto;
  }
}
</style>